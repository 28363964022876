
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as about_45passCWTjEkRXCqMeta } from "/codebuild/output/src823242230/src/src/pages/about-pass.vue?macro=true";
import { default as about_45yumenaviwK1Q5MFrBKMeta } from "/codebuild/output/src823242230/src/src/pages/about-yumenavi.vue?macro=true";
import { default as agreementk791L6Ik7PMeta } from "/codebuild/output/src823242230/src/src/pages/agreement.vue?macro=true";
import { default as confirm_45card_45aliaspSvUr2svsjMeta } from "/codebuild/output/src823242230/src/src/pages/card/[id]/confirm-card-alias.vue?macro=true";
import { default as confirmCH6lipt8JiMeta } from "/codebuild/output/src823242230/src/src/pages/card/[id]/confirm.vue?macro=true";
import { default as edit_45card_45aliasQSijgXxQ5dMeta } from "/codebuild/output/src823242230/src/src/pages/card/[id]/edit-card-alias.vue?macro=true";
import { default as editc56blB09qkMeta } from "/codebuild/output/src823242230/src/src/pages/card/[id]/edit.vue?macro=true";
import { default as indexxXerjyClsjMeta } from "/codebuild/output/src823242230/src/src/pages/card/[id]/index.vue?macro=true";
import { default as confirm7a11pgZdXaMeta } from "/codebuild/output/src823242230/src/src/pages/card/create/confirm.vue?macro=true";
import { default as indexY2c1MAUJaCMeta } from "/codebuild/output/src823242230/src/src/pages/card/create/index.vue?macro=true";
import { default as indexgFMpzPX7GDMeta } from "/codebuild/output/src823242230/src/src/pages/card/index.vue?macro=true";
import { default as completeZCPxV8g96XMeta } from "/codebuild/output/src823242230/src/src/pages/contact-form/complete.vue?macro=true";
import { default as confirm9ow5UpnHAaMeta } from "/codebuild/output/src823242230/src/src/pages/contact-form/confirm.vue?macro=true";
import { default as registerYS1yzg9Q4KMeta } from "/codebuild/output/src823242230/src/src/pages/contact-form/register.vue?macro=true";
import { default as contact5lsTnhhpR0Meta } from "/codebuild/output/src823242230/src/src/pages/contact.vue?macro=true";
import { default as _91id_93F7GJIX5BuRMeta } from "/codebuild/output/src823242230/src/src/pages/coupon/[id].vue?macro=true";
import { default as indexOMrn7dcOb6Meta } from "/codebuild/output/src823242230/src/src/pages/coupon/index.vue?macro=true";
import { default as faqJFGxLTDqncMeta } from "/codebuild/output/src823242230/src/src/pages/faq.vue?macro=true";
import { default as indexBfJHhvJOAGMeta } from "/codebuild/output/src823242230/src/src/pages/history/index.vue?macro=true";
import { default as _91id_93Aq6qV1PSiHMeta } from "/codebuild/output/src823242230/src/src/pages/history/receipt/[id].vue?macro=true";
import { default as indexfeA7iOYG5OMeta } from "/codebuild/output/src823242230/src/src/pages/index.vue?macro=true";
import { default as maintenancelh9P9YqrcSMeta } from "/codebuild/output/src823242230/src/src/pages/maintenance.vue?macro=true";
import { default as completeHCkILlnRtlMeta } from "/codebuild/output/src823242230/src/src/pages/membership/complete.vue?macro=true";
import { default as confirmDAN3eX1dA2Meta } from "/codebuild/output/src823242230/src/src/pages/membership/confirm.vue?macro=true";
import { default as registerLraW5omoV1Meta } from "/codebuild/output/src823242230/src/src/pages/membership/register.vue?macro=true";
import { default as confirm_45codeV7OjCgmsfSMeta } from "/codebuild/output/src823242230/src/src/pages/membership/yumenavi/confirm-code.vue?macro=true";
import { default as confirmVAzw7IJQfgMeta } from "/codebuild/output/src823242230/src/src/pages/membership/yumenavi/confirm.vue?macro=true";
import { default as register78IOiU1NbKMeta } from "/codebuild/output/src823242230/src/src/pages/membership/yumenavi/register.vue?macro=true";
import { default as menuOSy8yfDj6VMeta } from "/codebuild/output/src823242230/src/src/pages/menu.vue?macro=true";
import { default as indexPN4vTX88dAMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/index.vue?macro=true";
import { default as indexnW3ntpSxesMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/integration/index.vue?macro=true";
import { default as sampleos9LioqP3KMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/integration/sample.vue?macro=true";
import { default as completeNMRvOZUwThMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/membership/email-edit/complete.vue?macro=true";
import { default as confirm_45codeoxK6K0vfIxMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/membership/email-edit/confirm-code.vue?macro=true";
import { default as indexHpgJypxkUqMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/membership/email-edit/index.vue?macro=true";
import { default as indexF7MQ8A0V11Meta } from "/codebuild/output/src823242230/src/src/pages/mypage/membership/index.vue?macro=true";
import { default as completelTypEzmvI4Meta } from "/codebuild/output/src823242230/src/src/pages/mypage/membership/others-edit/complete.vue?macro=true";
import { default as confirmfEuoMgHnO1Meta } from "/codebuild/output/src823242230/src/src/pages/mypage/membership/others-edit/confirm.vue?macro=true";
import { default as indexXE5I7kB5msMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/membership/others-edit/index.vue?macro=true";
import { default as updatevK0xqb7OHnMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/membership/others-edit/update.vue?macro=true";
import { default as confirmLaCz0aOASKMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue?macro=true";
import { default as index5BZEoSDLtrMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue?macro=true";
import { default as updateORSWBseiOEMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue?macro=true";
import { default as completeH2eme0B50fMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/membership/password-edit/complete.vue?macro=true";
import { default as updateuhQhxnO2dzMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/membership/password-edit/update.vue?macro=true";
import { default as indexznc440U7TAMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/pass/index.vue?macro=true";
import { default as _91id_93f20gfN7NXFMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/pass/receipt/[id].vue?macro=true";
import { default as complete16L4IxfKonMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/sign-out/complete.vue?macro=true";
import { default as confirmKSMMTOuGA1Meta } from "/codebuild/output/src823242230/src/src/pages/mypage/sign-out/confirm.vue?macro=true";
import { default as completeUridJkyGBpMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/unsubscribe/complete.vue?macro=true";
import { default as confirmR9KR5mOmySMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/unsubscribe/confirm.vue?macro=true";
import { default as consentLnYXwkJR3pMeta } from "/codebuild/output/src823242230/src/src/pages/mypage/unsubscribe/consent.vue?macro=true";
import { default as _91id_93QBlRtBvgEGMeta } from "/codebuild/output/src823242230/src/src/pages/notice/[id].vue?macro=true";
import { default as indexq8vYpYHxGWMeta } from "/codebuild/output/src823242230/src/src/pages/notice/index.vue?macro=true";
import { default as _91id_93GmLqcT7PV9Meta } from "/codebuild/output/src823242230/src/src/pages/parking/[id].vue?macro=true";
import { default as pass_45agreementfJEMTXX1GrMeta } from "/codebuild/output/src823242230/src/src/pages/pass-agreement.vue?macro=true";
import { default as completey2rC6LVETLMeta } from "/codebuild/output/src823242230/src/src/pages/reset-password/complete.vue?macro=true";
import { default as confirm_45codecQ1XHWdibEMeta } from "/codebuild/output/src823242230/src/src/pages/reset-password/confirm-code.vue?macro=true";
import { default as new_45passwordUpk9zC7O0jMeta } from "/codebuild/output/src823242230/src/src/pages/reset-password/new-password.vue?macro=true";
import { default as send_45email9ObhfpgRs3Meta } from "/codebuild/output/src823242230/src/src/pages/reset-password/send-email.vue?macro=true";
import { default as confirm_45codeVV4f1I6oSqMeta } from "/codebuild/output/src823242230/src/src/pages/sign-in/confirm-code.vue?macro=true";
import { default as indexLMndeRN9nvMeta } from "/codebuild/output/src823242230/src/src/pages/sign-in/index.vue?macro=true";
import { default as confirm_45codeakduGBwzUCMeta } from "/codebuild/output/src823242230/src/src/pages/sign-up/confirm-code.vue?macro=true";
import { default as indexg1ZJHPl9eRMeta } from "/codebuild/output/src823242230/src/src/pages/sign-up/index.vue?macro=true";
import { default as tutorialIfF0KxsprfMeta } from "/codebuild/output/src823242230/src/src/pages/tutorial.vue?macro=true";
export default [
  {
    name: "about-pass",
    path: "/about-pass",
    component: () => import("/codebuild/output/src823242230/src/src/pages/about-pass.vue")
  },
  {
    name: "about-yumenavi",
    path: "/about-yumenavi",
    component: () => import("/codebuild/output/src823242230/src/src/pages/about-yumenavi.vue")
  },
  {
    name: "agreement",
    path: "/agreement",
    component: () => import("/codebuild/output/src823242230/src/src/pages/agreement.vue")
  },
  {
    name: "card-id-confirm-card-alias",
    path: "/card/:id()/confirm-card-alias",
    meta: confirm_45card_45aliaspSvUr2svsjMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/card/[id]/confirm-card-alias.vue")
  },
  {
    name: "card-id-confirm",
    path: "/card/:id()/confirm",
    meta: confirmCH6lipt8JiMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/card/[id]/confirm.vue")
  },
  {
    name: "card-id-edit-card-alias",
    path: "/card/:id()/edit-card-alias",
    meta: edit_45card_45aliasQSijgXxQ5dMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/card/[id]/edit-card-alias.vue")
  },
  {
    name: "card-id-edit",
    path: "/card/:id()/edit",
    meta: editc56blB09qkMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/card/[id]/edit.vue")
  },
  {
    name: "card-id",
    path: "/card/:id()",
    component: () => import("/codebuild/output/src823242230/src/src/pages/card/[id]/index.vue")
  },
  {
    name: "card-create-confirm",
    path: "/card/create/confirm",
    meta: confirm7a11pgZdXaMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/card/create/confirm.vue")
  },
  {
    name: "card-create",
    path: "/card/create",
    meta: indexY2c1MAUJaCMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/card/create/index.vue")
  },
  {
    name: "card",
    path: "/card",
    component: () => import("/codebuild/output/src823242230/src/src/pages/card/index.vue")
  },
  {
    name: "contact-form-complete",
    path: "/contact-form/complete",
    meta: completeZCPxV8g96XMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/contact-form/complete.vue")
  },
  {
    name: "contact-form-confirm",
    path: "/contact-form/confirm",
    meta: confirm9ow5UpnHAaMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/contact-form/confirm.vue")
  },
  {
    name: "contact-form-register",
    path: "/contact-form/register",
    meta: registerYS1yzg9Q4KMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/contact-form/register.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src823242230/src/src/pages/contact.vue")
  },
  {
    name: "coupon-id",
    path: "/coupon/:id()",
    component: () => import("/codebuild/output/src823242230/src/src/pages/coupon/[id].vue")
  },
  {
    name: "coupon",
    path: "/coupon",
    meta: indexOMrn7dcOb6Meta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/coupon/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src823242230/src/src/pages/faq.vue")
  },
  {
    name: "history",
    path: "/history",
    meta: indexBfJHhvJOAGMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/history/index.vue")
  },
  {
    name: "history-receipt-id",
    path: "/history/receipt/:id()",
    component: () => import("/codebuild/output/src823242230/src/src/pages/history/receipt/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexfeA7iOYG5OMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/codebuild/output/src823242230/src/src/pages/maintenance.vue")
  },
  {
    name: "membership-complete",
    path: "/membership/complete",
    meta: completeHCkILlnRtlMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/membership/complete.vue")
  },
  {
    name: "membership-confirm",
    path: "/membership/confirm",
    meta: confirmDAN3eX1dA2Meta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/membership/confirm.vue")
  },
  {
    name: "membership-register",
    path: "/membership/register",
    meta: registerLraW5omoV1Meta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/membership/register.vue")
  },
  {
    name: "membership-yumenavi-confirm-code",
    path: "/membership/yumenavi/confirm-code",
    meta: confirm_45codeV7OjCgmsfSMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/membership/yumenavi/confirm-code.vue")
  },
  {
    name: "membership-yumenavi-confirm",
    path: "/membership/yumenavi/confirm",
    meta: confirmVAzw7IJQfgMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/membership/yumenavi/confirm.vue")
  },
  {
    name: "membership-yumenavi-register",
    path: "/membership/yumenavi/register",
    meta: register78IOiU1NbKMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/membership/yumenavi/register.vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: menuOSy8yfDj6VMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/menu.vue")
  },
  {
    name: "mypage",
    path: "/mypage",
    meta: indexPN4vTX88dAMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/index.vue")
  },
  {
    name: "mypage-integration",
    path: "/mypage/integration",
    meta: indexnW3ntpSxesMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/integration/index.vue")
  },
  {
    name: "mypage-integration-sample",
    path: "/mypage/integration/sample",
    meta: sampleos9LioqP3KMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/integration/sample.vue")
  },
  {
    name: "mypage-membership-email-edit-complete",
    path: "/mypage/membership/email-edit/complete",
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/membership/email-edit/complete.vue")
  },
  {
    name: "mypage-membership-email-edit-confirm-code",
    path: "/mypage/membership/email-edit/confirm-code",
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/membership/email-edit/confirm-code.vue")
  },
  {
    name: "mypage-membership-email-edit",
    path: "/mypage/membership/email-edit",
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/membership/email-edit/index.vue")
  },
  {
    name: "mypage-membership",
    path: "/mypage/membership",
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/membership/index.vue")
  },
  {
    name: "mypage-membership-others-edit-complete",
    path: "/mypage/membership/others-edit/complete",
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/membership/others-edit/complete.vue")
  },
  {
    name: "mypage-membership-others-edit-confirm",
    path: "/mypage/membership/others-edit/confirm",
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/membership/others-edit/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit",
    path: "/mypage/membership/others-edit",
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/membership/others-edit/index.vue")
  },
  {
    name: "mypage-membership-others-edit-update",
    path: "/mypage/membership/others-edit/update",
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/membership/others-edit/update.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-confirm",
    path: "/mypage/membership/others-edit/yumenavi/confirm",
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi",
    path: "/mypage/membership/others-edit/yumenavi",
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-update",
    path: "/mypage/membership/others-edit/yumenavi/update",
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue")
  },
  {
    name: "mypage-membership-password-edit-complete",
    path: "/mypage/membership/password-edit/complete",
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/membership/password-edit/complete.vue")
  },
  {
    name: "mypage-membership-password-edit-update",
    path: "/mypage/membership/password-edit/update",
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/membership/password-edit/update.vue")
  },
  {
    name: "mypage-pass",
    path: "/mypage/pass",
    meta: indexznc440U7TAMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/pass/index.vue")
  },
  {
    name: "mypage-pass-receipt-id",
    path: "/mypage/pass/receipt/:id()",
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/pass/receipt/[id].vue")
  },
  {
    name: "mypage-sign-out-complete",
    path: "/mypage/sign-out/complete",
    meta: complete16L4IxfKonMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/sign-out/complete.vue")
  },
  {
    name: "mypage-sign-out-confirm",
    path: "/mypage/sign-out/confirm",
    meta: confirmKSMMTOuGA1Meta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/sign-out/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-complete",
    path: "/mypage/unsubscribe/complete",
    meta: completeUridJkyGBpMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/unsubscribe/complete.vue")
  },
  {
    name: "mypage-unsubscribe-confirm",
    path: "/mypage/unsubscribe/confirm",
    meta: confirmR9KR5mOmySMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/unsubscribe/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-consent",
    path: "/mypage/unsubscribe/consent",
    meta: consentLnYXwkJR3pMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/mypage/unsubscribe/consent.vue")
  },
  {
    name: "notice-id",
    path: "/notice/:id()",
    component: () => import("/codebuild/output/src823242230/src/src/pages/notice/[id].vue")
  },
  {
    name: "notice",
    path: "/notice",
    meta: indexq8vYpYHxGWMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/notice/index.vue")
  },
  {
    name: "parking-id",
    path: "/parking/:id()",
    meta: _91id_93GmLqcT7PV9Meta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/parking/[id].vue")
  },
  {
    name: "pass-agreement",
    path: "/pass-agreement",
    component: () => import("/codebuild/output/src823242230/src/src/pages/pass-agreement.vue")
  },
  {
    name: "reset-password-complete",
    path: "/reset-password/complete",
    meta: completey2rC6LVETLMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/reset-password/complete.vue")
  },
  {
    name: "reset-password-confirm-code",
    path: "/reset-password/confirm-code",
    meta: confirm_45codecQ1XHWdibEMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/reset-password/confirm-code.vue")
  },
  {
    name: "reset-password-new-password",
    path: "/reset-password/new-password",
    meta: new_45passwordUpk9zC7O0jMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/reset-password/new-password.vue")
  },
  {
    name: "reset-password-send-email",
    path: "/reset-password/send-email",
    meta: send_45email9ObhfpgRs3Meta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/reset-password/send-email.vue")
  },
  {
    name: "sign-in-confirm-code",
    path: "/sign-in/confirm-code",
    meta: confirm_45codeVV4f1I6oSqMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/sign-in/confirm-code.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexLMndeRN9nvMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/sign-in/index.vue")
  },
  {
    name: "sign-up-confirm-code",
    path: "/sign-up/confirm-code",
    meta: confirm_45codeakduGBwzUCMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/sign-up/confirm-code.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexg1ZJHPl9eRMeta || {},
    component: () => import("/codebuild/output/src823242230/src/src/pages/sign-up/index.vue")
  },
  {
    name: "tutorial",
    path: "/tutorial",
    component: () => import("/codebuild/output/src823242230/src/src/pages/tutorial.vue")
  }
]